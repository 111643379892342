#right-sidebar {
  @include media-breakpoint-up(md) {
    display: block;
    max-width: 426px;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 31px;
  }

  .widget_nav_menu {
    ul.menu {
      padding-left: 0;
      list-style-type: none;
    }

    li.menu-item {
      background-color: #fff;
      border: 1px solid $color_light-gray;

      a {
        display: block;
        font-size: 18px;
        @extend %font-futogo;
        padding: 1em;

        &:hover {
          text-decoration: none;
        }
      }
    }
    #menu-side-facility-2 {
      a {
        padding: 0;
        &:hover {
          opacity: .6;
        }
      }
    }
  }
}

body.page-id-14,
body.page-id-17 {
  #right-sidebar {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
      max-width: 426px;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 31px;
    }
  }
}


body.single-job_search,
body.single-shop_blog {
  #right-sidebar {
    .shop-extract {
      background-color: #fff;
    }

    .shop-name-wrap {
      border-top: 1px solid $color_light-gray;
      padding: 30px;
    }

    .shop-name {
      h2 {
        @extend %font-futogo;
        font-size: 18px;
      }

      p {
        font-size: 13px;
      }
    }

    .shop-cat {
      @extend %font-futogo;

      .ja {
        margin-top: 1.5em;
        margin-bottom: 0;
        font-size: 16px;
      }

      .en {
        font-size: 13px;
      }
    }
  }
}