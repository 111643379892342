body.page-id-3 {
  .policy {
    h1,h2 {
      @extend %font-futogo;
    }
    h1 {
      margin-top: 2em;
      font-size: 20px;
      @include media-breakpoint-up(md) {
        font-size: 26px;
      }
    }
    h2 {
      margin-top: 2em;
      font-size: 18px;
      @include media-breakpoint-up(md) {
        font-size: 20px;
        margin-top: 3em;
      }
    }
    ol {
      padding-left: 1.5em;
    }
  }
}
