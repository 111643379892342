body.page-id-734,
body.single-shop_blog,
body.single-staff_s_blog,
body.single-event,
body.single-gobangai_news {
  .main-content {
    padding-top: 15px;
    padding-bottom: 15px;
    @include media-breakpoint-up(md) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .entry-meta {
      .posted-on {
        @extend %font-lato;
        color: $color_gray_txt;
      }

      .staff {
        font-size: 11px;
        color: $color_green;
        margin-left: 2em;
        @include media-breakpoint-up(md) {
          font-size: 13px;
        }
      }
    }

    .entry-title {
      margin: 1em 0 2em;
    }

    .entry-header {
      border-bottom: 1px solid $color_gray_border;
      margin-bottom: 2em;
    }

    .entry-footer {
      margin-top: 4em;
    }

    .entry-content {
      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      figure {
        @include media-breakpoint-up(md) {
          width: 774px !important;
        }
      }
    }
  }

  .post-navigation {
    margin-top: 2em;
    margin-bottom: 2em;

    .btn,
    span {
      min-width: 90px;
      @include mq(se) {
        min-width: 100px;
      }
      @include media-breakpoint-up(md) {
        min-width: 120px;
      }
    }

    .btn {
      margin: 0 3px;
    }
  }

  #right-sidebar {
    .shop-extract {
      margin-bottom: 2em;
    }
  }

}

body.single-job_search {
  .post-navigation {
    margin-top: 2em;
    margin-bottom: 2em;

    .btn,
    span {
      min-width: 90px;
      @include mq(se) {
        min-width: 100px;
      }
      @include media-breakpoint-up(md) {
        min-width: 120px;
      }
    }

    .btn {
      margin: 0 3px;
    }
  }
}


body.single-gobangai_news,
body.single-event,
body.single-shop_blog,
body.single-staff_s_blog {
  #main {
    .entry-content {
      h1, h2, h3, h4, h5, h6 {
        line-height: 1.4;
        @extend %font-futogo;
      }

      h1, h2 {
        font-size: 18px;
      }

      h3, h4 {
        font-size: 15px;
      }

      h5, h6 {
        font-size: 13px;
      }
    }
  }
}
