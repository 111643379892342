body.page-id-14,
body.page-id-635,
body.page-id-639 {
  #concept {
    .img-block {
      text-align: center;
      margin: 3em 0;
      @include media-breakpoint-up(md) {
        margin: 5em 0;
      }

      img {
        width: 50%;
        @include media-breakpoint-up(md) {
          width: 40%;
        }
      }
    }

    .txt-blocl {
      p {
        line-height: 1.7;
      }
    }

  }

  #feature {
  }

  #hours-open {
    .row {
      margin-bottom: 1em;
    }

    table {
      margin-bottom: 0;
      border-bottom: 0;

      th {
        background-color: $color_light-gray;
        width: 10em;
        font-weight: normal;
      }

      th, td {
        border-bottom: 0;
      }

      &.table-5 {
        border-bottom: 1px solid $color_gray_border;

        th, td {
          border-bottom: 1px solid $color_gray_border;
        }
      }

      &.table-1 {
        .th-top {
          border-bottom: 0;
          padding-top: 1.5rem;
          padding-bottom: 0;
        }

        .th-btm {
          border-top: 0;
          padding-top: 0;
          padding-bottom: 1.5rem;
        }
      }

      @include media-breakpoint-up(md) {
        &.table-1,
        &.table-3 {
          border-right: 0;

          td {
            border-right: 0;
          }
        }
        &.table-4 {
          border-bottom: 1px solid $color_gray_border;

          th, td {
            border-bottom: 1px solid $color_gray_border;
          }
        }
      }
    }
  }

  #spot {
    .item-spot {
      margin-bottom: 2em;
      @include media-breakpoint-up(md) {
        margin-bottom: 3em;
      }
    }

    .txt-block {
      position: relative;
    }

    dl.sp-side {
      @include media-breakpoint-up(md) {
        position: absolute;
        bottom: 0;
      }
    }
  }

  #service {
    .item-service {
      margin-bottom: 2em;
      @include media-breakpoint-up(md) {
        margin-bottom: 3em;
      }

      > .row {
        align-items: center;
      }

      .img-block {
        text-align: center;
      }

      dt.no-bdr,
      dd.no-bdr {
        border-bottom: 0;
      }

      p.bdr-top {
        padding-top: .5rem;
        border-top: 1px solid $color_light-gray;
      }

      &:nth-of-type(1) {
        .img-block {
          img {
            width: 50%;
            @include media-breakpoint-up(md) {
              width: 70%;
            }
          }
        }

        dl {
          margin-bottom: 0;

          ul {
            padding-left: 1.5em;
            font-size: 12px;
            @include media-breakpoint-up(md) {
              font-size: 13px;
            }
          }
        }

        .attention {
          margin-top: .5em;
          padding-left: 1em;
          font-size: 10px;
          @include media-breakpoint-up(md) {
            font-size: 13px;
          }
        }
      }

      &:nth-of-type(2) {
        .img-block {
          img {
            padding: 1rem 0;
            width: 30%;
            @include media-breakpoint-up(md) {
              width: 46%;
            }
          }
        }

        dl {
          margin-bottom: 0;
        }
      }

      &:nth-of-type(3) {
        .img-block {
          img {
            padding: 1rem 0;
            width: 26%;
            @include media-breakpoint-up(md) {
              width: 42%;
            }
          }
        }

        dl {
          margin-bottom: 0;
        }
      }

      &:nth-of-type(4) {
        .img-block {
          img {
            padding: 1rem 0;
            width: 60%;
            @include media-breakpoint-up(md) {
              width: 78%;
            }
          }
        }

        dl {
          margin-bottom: 0;
        }
      }

      &:nth-of-type(5) {
        .img-block {
          img {
            padding: 1rem 0;
            width: 45%;
            @include media-breakpoint-up(md) {
              width: 60%;
            }
          }
        }

        dl {
          border-bottom: 1px solid $color_light-gray;
        }
      }

      &:nth-of-type(6) {
        .img-block {
          img {
            padding: 1rem 0;
            width: 26%;
            @include media-breakpoint-up(md) {
              width: 42%;
            }
          }
        }

        dl {
          margin-bottom: 0;
        }
      }

      &:nth-of-type(7) {
        th {
          background-color: $color_light-gray;
        }

        th, td {
          @include media-breakpoint-up(md) {
            text-align: center;
          }
        }

        dl.sp-side {
          margin-bottom: 0;
          border: 0;

          dt, dd {
            padding: 0;
            border: 0;
          }
        }
      }
    }
  }

  #request {
  }

  p.covid-close {
    padding-top: 1em;
    @include media-breakpoint-up(md) {
      padding-top: 2em;
    }
  }

  dt.covid-close {
    @include media-breakpoint-up(md) {
      padding-top: 1.5em;
    }
  }

  dd.covid-close {
    @include media-breakpoint-up(md) {
      height: 2em;
    }
  }
}


body.page-id-635 #service .item-service:nth-of-type(3) .img-block img,
body.page-id-639 #service .item-service:nth-of-type(3) .img-block img {
  width: 60%;
}


body.page-id-635 {
  p.covid-close {
    @include media-breakpoint-up(md) {
      padding-top: 3em;
    }
  }
}

