body.page-id-635,
body.page-id-639 {
  .site {
    #link-shops {
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }

  #wrapper-footer {
    padding-bottom: 60px;
  }

  #payment {
    margin-bottom: 2em;
    padding-bottom: 2em;
    border-bottom: 1px solid $color_gray_border;
    @include media-breakpoint-up(md) {
      margin-bottom: 3em;
      padding-bottom: 3em;
    }
  }

  #right-sidebar {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }


  #service {
    .item-service {
      &:nth-of-type(1) {
        .img-block {
          img {
            padding: 1rem 0;
            width: 30%;
            @include media-breakpoint-up(md) {
              width: 46%;
            }
          }
        }
      }
      &:nth-of-type(2) {
        .img-block {
          img {
            padding: 1rem 0;
            width: 26%;
            @include media-breakpoint-up(md) {
              width: 42%;
            }
          }
        }
      }
      &:nth-of-type(3) {
        .img-block {
          img {
            padding: 1rem 0;
            width: 60%;
            @include media-breakpoint-up(md) {
              width: 78%;
            }
          }
        }
      }
      &:nth-of-type(4) {
        .img-block {
          img {
            padding: 1rem 0;
            width: 45%;
            @include media-breakpoint-up(md) {
              width: 60%;
            }
          }
        }
      }
    }
  }
}

body.page-id-637 {
  #shop_search-cat {
    li {
      width: 49%;
    }
  }
}

body.page-id-642 { // cn shops
  .japan-tax-free {
    margin-bottom: 1em;
    @include media-breakpoint-up(md) {
      margin-bottom: 2em;
    }
  }

  .japan-tax-free-wrap {
    background-color: #fff;
    text-align: center;
    padding: 1em 0;
    @include media-breakpoint-up(md) {
      padding: 2em 0;
    }

    img {
      max-width: 60%;
      @include media-breakpoint-up(md) {
        max-width: 40%;
      }
    }
  }

  .cn-entry-item {
    margin-bottom: 1em;
    background-color: #fff;
    border: 1px solid $color_gray_border;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
      width: 100%;
    }

    .img-block {
      text-align: center;
      border-bottom: 1px solid $color_gray_border;
      @include media-breakpoint-up(md) {
        border-bottom: 0;
        width: 315px;
      }

      img {
        max-width: 50%;
        @include media-breakpoint-up(md) {
          max-width: 250px;
        }
      }
    }

    .txt-block {
      padding: 20px 15px 15px;
      @include media-breakpoint-up(md) {
        flex: 1;
        border-left: 1px solid $color_gray_border;
        padding-left: 25px;
      }

      .entry-title {
        @extend %font-futogo;
        font-size: 20px;
      }

      .description {
        margin-right: -15px;
        margin-left: -15px;
        border-top: 1px solid $color_gray_border;
        margin-bottom: 0;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;
        @include media-breakpoint-up(md) {
          margin-left: -25px;
          padding-left: 25px;
        }
      }
    }
  }
}

