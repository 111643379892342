#body-loader {
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1.0);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20000;

  img {
    position: absolute;
    @include centering-both();
    width: 60px;
    height: 15px;
  }
}

#site-header {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  @include media-breakpoint-up(lg) {
    padding: 10px 0;
  }
}

.pc-menu-wapper {
  display: none !important;
  @include media-breakpoint-up(lg) {
    display: flex !important;
  }
}

.sp-menu-wrapper {
  display: block;
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.navbar {
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  letter-spacing: .1em;
  @include media-breakpoint-up(xs) {
  }
  @include media-breakpoint-up(lg) {
    @include transition03;
    padding: .7rem 1rem;
  }

  h1 {
    @include media-breakpoint-up(md) {
      margin-right: 0;
      padding-bottom: 0;
      width: 102px;
      height: 0;
      overflow: hidden;
      background: url("../img/logo.svg") no-repeat;
      background-size: contain;
      padding-top: 57px;
    }
  }

  .navbar-brand {
    font-size: 4vw;
    font-weight: 700;
    padding-left: 15px;
    @include mq(se) {
      font-size: 18px;
    }
    @include media-breakpoint-up(lg) {
      padding-left: 0;
      margin-right: 0;
      padding-bottom: 0;
      //width: 260px;
      width: 102px;
      height: 0;
      overflow: hidden;
      background: url("../img/logo.svg") no-repeat;
      background-size: contain;
      padding-top: 57px;
    }
  }

  > .container {
    > .pc-menu-wapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include media-breakpoint-up(lg) {
        min-width: auto;
      }
      @include media-breakpoint-up(xl) {
        max-width: 1000px;
        width: 80%;
      }
    }
  }
}

body.is-scroll {
  #site-header {
    border-bottom: 1px solid $color_gray_border;
  }
}

#wrapper-navbar.is-scroll {
  .navbar {
    @include media-breakpoint-up(md) {
      padding: 0 1rem;
      .navbar-brand {
        background: none;
        width: auto;
        height: auto;
        padding: 0;
      }
      .nav-link {
        .nav-en {
          display: none;
        }

        .menu_description {
          font-size: 16px;
          font-family: a-otf-futo-go-b101-pr6n, sans-serif;
          font-weight: 500;
          font-style: normal;
        }
      }
    }
  }
}

#main-menu {
  @include media-breakpoint-up(xl) {
    min-width: 765px;
    justify-content: space-between;
    font-size: 15px;
  }

  .menu_description {
    display: block;
    text-align: center;
    font-weight: normal;
    font-size: 10px;
  }
}

.chg-lang-pc {
  position: relative;

  a.dropdown-toggle {
    &:after {
      display: none;
    }
  }

  .dropdown-menu {
    font-size: 14px;
    left: auto;
    right: 0;
    border-radius: 0;
    padding: 0;
    text-align: center;
  }

  .dropdown-item {
    &:hover {
      background-color: $color_green;
      color: #fff;
    }
  }
}


// for sp only

.sp-menu-cont {
  width: 100vw;
  position: absolute;
  top: 55px;
  left: 0;
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

#menu-gnav-sp {
  display: none;

  li {
    @include transition05;
    height: 0;
    opacity: 0;

    &.menu-item-36 {
      a {
        &:before {
          content: '✕ ';
        }
      }
    }

  }

  a {
    line-height: 1.75;
    border-top: 1px solid #fff;
    background-color: $color_navy;
    font-size: 14px;
    display: block;
    color: #fff;
    padding: .7rem 0;

    &:hover {
      text-decoration: none;
      background-color: #428acf;
    }
  }

  &.open {
    li {
      display: block;
      height: 45px;
      opacity: 1;
    }
  }
}

#menu-gnav-lang {
  display: none;

  li {
    @include transition05;
    height: 0;
    opacity: 0;

    &.menu-item-37 {
      a {
        &:before {
          content: '✕ ';
        }
      }
    }
  }

  a {
    line-height: 1.75;
    border-top: 1px solid #fff;
    background-color: $color_green;
    font-size: 14px;
    display: block;
    color: #fff;
    padding: .7rem 0;

    &:hover {
      text-decoration: none;
      background-color: #7bedec;
    }
  }

  &.open {
    li {
      display: block;
      height: 45px;
      opacity: 1;
    }
  }
}

.nav-btn-group {
  display: flex;
  align-items: center;

  button {
    padding: 0;
    border: 0;
    text-align: center;
    min-width: 55px;
    min-height: 55px;
    @include media-breakpoint-up(xs) {
      min-width: 60px;
      min-height: 60px;
    }

    .label {
      display: block;
    }
  }

  font-weight: 400;
  font-size: 2.5vw;
  @include media-breakpoint-up(xs) {
    font-size: 10px;
  }

  .icon-lang,
  span.icon {
    display: block;
    margin: 0 auto;
  }

  .btn-lang {
    color: #fff;
    background-color: $color_green;

    .label {
      display: none;
      margin-top: 2px;
    }
  }

  .btn-nav {
    color: #fff;
    background-color: $color_navy;

    .label {
      display: none;
      margin-top: 18px;
    }
  }

  .icon {
    display: block;
    width: 27px;
    height: 2px;
    margin: 0 auto;
    background-color: #fff;
    position: relative;
    top: 0;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 27px;
      height: 2px;
      position: absolute;
      background-color: #fff;
      left: 0;
    }

    &:before {
      top: -6px;
    }

    &:after {
      top: 6px;
    }
  }


  #chg-lang-btn {
    //display: none;
  }
}

.menu-gnav-sp-container,
.menu-gnav-lang-container {
  ul {
    padding-left: 0;
    list-style-type: none;
    text-align: center;
  }
}

