body.single-event {
  .end-info {
    border: 1px solid #f00;
    text-align: center;
    padding: .3em 0;
    @extend %font-futogo;
    color: #f00;
    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  .event-meta {
    border-top: 1px solid $color_gray_border;
    padding: 10px 0;
    @include media-breakpoint-up(md) {
      padding: 20px 0;
    }
  }
}