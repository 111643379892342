body.page-id-5717 {
  .content-header {
    display: none;
  }

  .cashless-nav-wrap {
    margin-top: 2em;
    background-color: #fff;
    @include media-breakpoint-up(md) {
      margin-top: 4em;
    }

    > p {
      margin-bottom: 0;
      padding: .5em 0;
      text-align: center;
      border-top: 1px solid #e9e9e9;
      border-left: 1px solid #e9e9e9;
      border-right: 1px solid #e9e9e9;
    }

    .btn-success {
      background-color: #eb614b;

      &:hover {
        background-color: #fb8a78;
      }
    }
  }

  .cashless-view-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -15px;

    &:after {
      @include media-breakpoint-up(md) {
        content: '';
        display: block;
        width: 32%;
      }
      @include media-breakpoint-up(lg) {
        width: 24%;
      }
    }

    &:before {
      @include media-breakpoint-up(lg) {
        content: '';
        display: block;
        width: 24%;
        order: 1;
      }
    }
  }

  .cashless-nav-info {
    padding: 1em 1em 0 1em;

    h2 {
      text-align: center;
      font-size: 10px;
      font-weight: bold;
      margin: 1em 0;
      @include media-breakpoint-up(md) {
        font-size: 13px;
      }
    }

    p {
      font-size: 10px;
      @include media-breakpoint-up(md) {
        font-size: 13px;
        text-align: center;
      }

      a {
        text-decoration: underline;
      }
    }
  }

  .cashless-item {
    position: relative;
    width: 48%;
    margin-bottom: 15px;
    @include media-breakpoint-up(md) {
      width: 32%;
    }
    @include media-breakpoint-up(lg) {
      width: 24%;
    }

    a {
      &:hover {
        opacity: .6;
      }
    }

    p.info {
      position: absolute;
      width: 100%;
      text-align: center;
      margin-bottom: .5em;
      bottom: 0;
      font-size: 10px;
      @include media-breakpoint-up(md) {
        font-size: 13px;
      }
    }
  }

  .epos-bnr-block {
    margin-top: 2em;
    @include media-breakpoint-up(md) {
      margin-top: 4em;
    }
    a {
      &:hover {
        opacity: .6;
      }
    }
  }

  #wrapper-footer {
    padding-bottom: 60px;
  }

  #link-shops {
    &.btn-danger {
      background-color: #eb614b;

      &:hover {
        background-color: #fb8a78;
      }
    }
  }

  .touch-content {
    text-align: center;
    padding-bottom: 1em;
    margin-bottom: 1rem;
    img {
      height: 24px;
    }
  }

}