$color_job_search_index: #7fc1b6;

.job_search_index {
  background-color: #fff;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;

  .job_wrap {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    @include media-breakpoint-up(md) {
      max-width: 1024px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .hedding {
    text-align: center;
    color: $color_job_search_index;

    h2 {
      font-family: 'Roboto Condensed', sans-serif;
      letter-spacing: .1em;
      font-size: 24px;
      @include media-breakpoint-up(md) {
        font-size: 48px;
      }
    }

    p {
      font-size: 13px;
      letter-spacing: .1em;
      @include media-breakpoint-up(md) {
        font-size: 21px;
      }
    }
  }

  .job-search-btn {
    margin: 2em 0;
    text-align: center;
    @include media-breakpoint-up(md) {
      margin: 4em 0;
    }

    a {
      position: relative;
      display: inline-block;
      font-family: 'Roboto Condensed', sans-serif;
      letter-spacing: .1em;
      color: #fff;
      background-color: $color_job_search_index;
      font-size: 15px;
      padding: .5em 0;
      width: 290px;
      @include media-breakpoint-up(md) {
        font-size: 31px;
        width: 580px;
      }

      &:after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4.5px 0 4.5px 5px;
        border-color: transparent transparent transparent #ffffff;
        right: .6em;
        @include centering-vertical();
        @include media-breakpoint-up(md) {
          border-width: 9px 0 9px 9px;
        }
      }

      &:hover {
        text-decoration: none;
        opacity: .7;
      }
    }
  }

  section.hero {
    .hero_messege {
      display: none;
    }

    .page-nav {
      //margin-bottom: 2em;
      @include media-breakpoint-up(md) {
        border-bottom: 4px solid #e5e5e6;
        //margin-bottom: 4em;
      }

      ul {
        margin: 0;
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include media-breakpoint-up(md) {
          margin-right: auto;
          margin-left: auto;
          width: 100%;
          max-width: 1024px;
        }

        li {
          width: 50%;
          text-align: center;
          border-bottom: 2px solid #e5e5e6;
          padding: .4em 0;
          @include media-breakpoint-up(md) {
            width: 25%;
            border: none;
            padding: 1em 0;
          }

          &:nth-child(odd) {
            a {
              border-right: 1px solid $color_job_search_index;
            }
          }

          &:last-child {
            a {
              @include media-breakpoint-up(md) {
                border: none;
              }
            }
          }
        }

        a {
          font-weight: 700;
          display: inline-block;
          width: 100%;
          font-size: 13px;
          @include media-breakpoint-up(md) {
            font-size: 19px;
            padding: .3em 0;
            border-right: 1px solid $color_job_search_index;
          }

          &:hover {
            text-decoration: none;
            background-color: rgba(127, 193, 182, .5);
          }
        }
      }
    }

    .read {
      text-align: center;
      font-size: 12px;
      line-height: 2;
      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 2.4;
      }
    }
  }

  section.new-arrival {
    padding-top: 5em;
    background-color: #f2f1ef;
  }

  section.merit_cont {
    padding-top: 5em;
    background: #fffce8 url("../img/job_search_index/bg-yellow-top-bg-g.png") repeat-x;
    background-size: 87px auto;
    @include media-breakpoint-up(md) {
      background-size: 266px auto;
      padding-top: 11em;
    }
  }


  section.search-by-industry_cont {
    padding-top: 5em;
    padding-bottom: 1em;
    background: #f2f1ef url("../img/job_search_index/bg-gray-top-bg-y.png") repeat-x;
    background-size: 87px auto;
    @include media-breakpoint-up(md) {
      background-size: 266px auto;
      padding-top: 11em;
    }
  }


  section.pick-up_cont {
    padding-top: 5em;
    background: #fffce8 url("../img/job_search_index/bg-yellow-top.png") repeat-x;
    background-size: 87px auto;
    @include media-breakpoint-up(md) {
      background-size: 266px auto;
      padding-top: 11em;
    }
  }

  section.staff-interview_cont {
    padding-top: 5em;
    background: #fff url("../img/job_search_index/bg-white-top-bg-g.png") repeat-x;
    background-size: 87px auto;
    @include media-breakpoint-up(md) {
      background-size: 266px auto;
      padding-top: 11em;
    }
  }


  section.job-search-1 {
    padding-top: 5em;
    background: #f2f1ef url("../img/job_search_index/bg-gray-top-bg-y.png") repeat-x;
    background-size: 87px auto;
    padding-bottom: 1px;
    @include media-breakpoint-up(md) {
      background-size: 266px auto;
      padding-top: 11em;
    }
  }

  section.new-arrival {
    padding-bottom: 3em;

    .new-job-wrap {
      margin-top: 2em;
      @include media-breakpoint-up(md) {
        margin-top: 4em;
      }
    }

    .job-search-item {
      margin-bottom: 1em;
      background-color: #fff;
      border: 1px solid $color_gray_border;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }

      .logo-block {
        display: none;

        a {
          &:hover {
            opacity: .6;
          }
        }

        @include media-breakpoint-up(md) {
          display: block;
          img {
            width: 204px;
          }
        }
      }

      .shop-block {
        padding: 15px;
        border-bottom: 1px solid $color_gray_border;
        @include media-breakpoint-up(md) {
          border-bottom: 0;
          border-left: 1px solid $color_gray_border;
          min-width: 380px;
          padding: 23px 30px;
        }

        a {
          display: flex;
          flex-direction: column-reverse;
          @include media-breakpoint-up(md) {
            flex-direction: column;
          }

          &:hover {
            text-decoration: none;
          }
        }

        .job_shop_name {
          margin: 0;
          font-size: 13px;
          @include media-breakpoint-up(md) {
            font-size: 20px;
          }
        }

        .job_shop_sub_name {
          margin: 0;
          display: none;
          @include media-breakpoint-up(md) {
            display: block;
          }
        }

        .job_shop_cat {
          margin: 0;
          color: $color_gray_txt;
          font-size: 10px;
          @include media-breakpoint-up(md) {
            font-size: 14px;
          }
        }
      }

      .job-block {
        padding: 15px;
        @include media-breakpoint-up(md) {
          flex: 1;
          border-left: 1px solid $color_gray_border;
        }

        a {
          &:hover {
            text-decoration: none;
          }
        }

        .job_cat {
          margin: 0;
          line-height: 1.4;
          @include media-breakpoint-up(md) {
            font-size: 18px;
          }
        }

        .job_style {
          margin: 0;
          padding: 0;
          list-style-type: none;

          li {
            display: inline-block;
            font-size: 10px;
            @include media-breakpoint-up(md) {
              font-size: 14px;
            }

            &:after {
              content: ' ｜';
            }

            &:last-child {
              &:after {
                content: none;
              }
            }
          }
        }

        .job_post_time {
          margin: 0;
          color: $color_gray_txt;
          font-size: 10px;
          @include media-breakpoint-up(md) {
            font-size: 14px;
          }
        }
      }
    }
  }

  section.search-by-industry_cont {
    .industry-wrap {
      margin-top: 2em;
      @include media-breakpoint-up(md) {
        margin-top: 4em;
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        @include media-breakpoint-up(md) {
          justify-content: space-between;
        }
      }
    }

    li {
      text-align: center;
      margin-bottom: 1em;
      a {
        display: block;
        border-radius: 50%;
        width: 157px;
        height: 157px;
        position: relative;
        @include media-breakpoint-up(md) {
          width: 315px;
          height: 315px;
        }

        &:hover {
          opacity: .7;
        }
      }
    }


    li.fashon {
      a {
        background-color: #8498c6;
      }

      p {
        letter-spacing: .1em;
      }

      img {
        width: 35px;
        @include media-breakpoint-up(md) {
          width: 69px;
        }
      }
    }

    li.fashon_goods {
      a {
        background-color: #bf8ab5;
      }

      p {
        letter-spacing: .05em;
      }

      img {
        width: 24px;
        @include media-breakpoint-up(md) {
          width: 49px;
        }
      }
    }

    li.lifestyle_goods {
      a {
        background-color: #a9cc7d;
      }

      p {
        letter-spacing: .05em;
      }

      img {
        width: 40px;
        @include media-breakpoint-up(md) {
          width: 80px;
        }
      }
    }

    li.service {
      a {
        background-color: #7fc1b6;
      }

      p {
        letter-spacing: .1em;
      }

      img {
        width: 22px;
        @include media-breakpoint-up(md) {
          width: 44px;
        }
      }
    }

    li.food {
      a {
        background-color: #f4d146;
      }

      p {
        letter-spacing: .1em;
      }

      img {
        width: 33px;
        @include media-breakpoint-up(md) {
          width: 66px;
        }
      }
    }

    li.cafe_restaurant {
      a {
        background-color: #eaa568;
      }

      img {
        width: 33px;
        @include media-breakpoint-up(md) {
          width: 67px;
        }
      }
    }

    .icon {
      position: relative;
      height: 55%;

      img {
        position: absolute;
        @include centering-both();
      }
    }

    .txt {
      color: #fff;
      position: absolute;
      @include centering-horizontal();
      top: 55%;

      p {
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 17px;
        margin-bottom: 0;
        white-space: nowrap;
        @include media-breakpoint-up(md) {
          font-size: 35px;
        }
      }

      h3 {
        font-size: 10px;
        @include media-breakpoint-up(md) {
          font-size: 14px;
        }
      }
    }
  }

  section.pick-up_cont {
    padding-bottom: 3em;

    .pick-up_wrap {
      @include media-breakpoint-up(md) {
        margin: 3em 0 2em;
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      li {
        margin: .5em;

        &.regular {
          a {
            color: #fff;
            background-color: $color_job_search_index;
          }
        }
      }

      a {
        position: relative;
        display: inline-block;
        text-align: center;
        letter-spacing: .1em;
        color: $color_job_search_index;
        background-color: #f2f1ef;
        font-size: 11px;
        padding: .8em 0;
        width: 40vw;
        white-space: nowrap;
        @include media-breakpoint-up(md) {
          font-size: 22px;
          width: 17vw;
          max-width: 240px;
        }

        &:after {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 4.5px 0 4.5px 5px;
          border-color: transparent transparent transparent #ffffff;
          right: .6em;
          @include centering-vertical();
          @include media-breakpoint-up(md) {
            border-width: 9px 0 9px 9px;
          }
        }

        &:hover {
          text-decoration: none;
          opacity: .7;
        }
      }
    }
  }

  section.merit_cont {
    .merit_wrap {
      margin-left: auto;
      margin-right: auto;
      width: 65vw;
      @include media-breakpoint-up(md) {
        width: 100%;
      }

      ol {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }

      li {
        display: flex;
        flex-direction: column;
        @include media-breakpoint-up(md) {
          flex-direction: row;
          align-items: center;
        }
      }

      .img-block {
        margin: 2em 0 1em;
        @include media-breakpoint-up(md) {
          width: 497px;
        }
      }

      .txt-block {
        @include media-breakpoint-up(md) {
          flex: 1;
          margin-left: 3em;
        }
      }

      dt {
        position: relative;
        display: block;
        padding: .6em 0;
        text-align: center;
        font-size: 17px;
        letter-spacing: .1em;
        color: #fff;
        background-color: $color_job_search_index;
        margin-bottom: 1em;
        @include media-breakpoint-up(md) {
          padding: 1em 0;
          font-size: 34px;
        }

        &:after {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 9.5px 12px 9.5px;
          border-color: transparent transparent $color_job_search_index transparent;
          top: -11px;
          @include centering-horizontal();
          @include media-breakpoint-up(md) {
            border-width: 19px 24px 19px 0;
            border-color: transparent $color_job_search_index transparent transparent;

            @include centering-vertical();
            left: -23px;
          }
        }
      }

      p {
        font-weight: 400;
        font-size: 13px;
        line-height: 1.8;
        @include media-breakpoint-up(md) {
          font-size: 20px;
        }
      }
    }
  }

  section.staff-interview_cont {
    .item {
      margin-bottom: 3em;
      @include media-breakpoint-up(md) {
        margin-bottom: 8em;
      }

    }

    .item-1, .item-2 {
      .cat {
        img {
          width: 73px;
          @include media-breakpoint-up(md) {
            width: 146px;
          }
        }
      }
    }

    .item-3 {
      .cat {
        img {
          width: 130px;
          @include media-breakpoint-up(md) {
            width: 259px;
          }
        }
      }
    }

    .item-4 {
      .cat {
        img {
          width: 89px;
          @include media-breakpoint-up(md) {
            width: 301px;
          }
        }
      }
    }

    .staff-interview_wrap {
      padding-top: 3em;
    }

    .prof {
      display: flex;
      flex-direction: column;
      overflow-x: hidden;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: center;
      }

      .img-block {
        text-align: center;

        img {
          width: 80vw;
          @include media-breakpoint-up(md) {
            width: 576px;
          }
        }
      }

      .txt-block {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        @include media-breakpoint-up(md) {
          margin-left: 2em;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }

    .name {
      .meta {
        color: $color_job_search_index;
      }

      .shop {
        font-size: 18px;
        @include media-breakpoint-up(md) {
          font-size: 32px;
        }
      }

      .ttl {
        font-size: 11px;
        @include media-breakpoint-up(md) {
          font-size: 22px;
        }
      }

      h3 {
        margin-top: .5em;
        font-size: 20px;
        font-weight: 700;
        @include media-breakpoint-up(md) {
          font-size: 41px;
        }

        span {
          font-weight: 500;
          font-size: 10px;
          @include media-breakpoint-up(md) {
            font-size: 21px;
          }
        }
      }
    }

    .faq {
      padding: 0 1em;
      @include media-breakpoint-up(md) {
        padding: 0;
      }

      ol {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }

      dt {
        margin-top: 2em;
        position: relative;
        color: $color_job_search_index;
        padding-left: 2.5em;
        padding-top: .5em;
        font-size: 15px;
        @include media-breakpoint-up(md) {
          margin-bottom: .5em;
          font-size: 25px;
          padding-top: 1em;
          padding-left: 3em;
        }

        span {
          position: absolute;
          top: 0;
          left: 0;
          font-family: 'Roboto Condensed', sans-serif;
          font-size: 24px;
          @include media-breakpoint-up(md) {
            font-size: 48px;
          }
        }
      }

      dd {
        margin: 1em 0;
      }

      p {
        font-weight: 400;
        font-size: 13px;
        line-height: 1.8;
        @include media-breakpoint-up(md) {
          font-size: 19px;
          line-height: 2;
        }
      }
    }

    .messege {
      background-color: #f2f1ef;
      width: 100vw;
      margin: 4em calc(50% - 50vw);
      padding-top: 3em;
      @include media-breakpoint-up(md) {
        padding-top: 0;
      }

      .messege-inner {
        @include media-breakpoint-up(md) {
          margin-left: auto;
          margin-right: auto;
          max-width: 1024px;
          display: flex;
          align-items: center;
        }
      }

      .txt-block {
        padding: 0 30px;
        @include media-breakpoint-up(md) {
          width: 50%;
          padding-left: 0;
        }
      }

      .img-block {
        @include media-breakpoint-up(md) {
          //img {
          //  max-width: none;
          //  width: 50vw;
          //  margin-right: calc((50vw - 50%) * -1);
          //}
          width: 50%;
        }
      }

      h3 {
        font-family: 'MyBahagia';
        letter-spacing: .1em;
        color: $color_job_search_index;
        text-align: center;
        font-size: 37px;
        @include media-breakpoint-up(md) {
          font-size: 63px;
        }
      }

      p {
        font-weight: 400;
        font-size: 13px;
        line-height: 1.8;
        @include media-breakpoint-up(md) {
          font-size: 19px;
        }
      }
    }
  }

  #wrapper-footer {
    padding-top: 5em;
    background: #fff url("../img/job_search_index/bg-footer.png") repeat-x;
    background-size: 87px auto;
    @include media-breakpoint-up(md) {
      background-size: 266px auto;
      padding-top: 11em;
    }

    .foot-logo-block {
      margin-left: auto;
      margin-right: auto;
      width: 88px;
      @include media-breakpoint-up(md) {
        width: 176px;
      }
    }
  }

  .job_search_index-footer-nav {
    ul {
      display: flex;
      justify-content: center;
      list-style-type: none;
      padding: 0;
      margin: 1em 0;
      @include media-breakpoint-up(md) {
        margin: 3em 0;
      }
    }

    li {
      margin: 0 1em;
      @include media-breakpoint-up(md) {
        margin: 0 2em;
      }
    }

    a {
      position: relative;
      display: inline-block;
      font-size: 10px;
      padding-left: 1em;
      vertical-align: middle;
      @include media-breakpoint-up(md) {
        font-size: 18px;
        padding-left: 1.5em;
      }

      &:before {
        position: absolute;
        content: '';
        left: 0px;
        top: 4px;
        width: 6px;
        height: 6px;
        border-top: 1px solid #000;
        border-right: 1px solid #000;
        transform: rotate(45deg);
        @include media-breakpoint-up(md) {
          top: 7px;
          width: 12px;
          height: 12px;
        }
      }
    }

    .sample5-1::before {
      left: 3px;
      width: 4px;
      height: 4px;
      border-top: 1px solid #7a0;
      border-right: 1px solid #7a0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .site-info {
    margin: 0 0 3em 0;
    text-align: center;
    color: #000;
    @include media-breakpoint-up(md) {
      font-size: 15px;
    }
  }
}


/* フェードイン(初期値) */
.js-fadeUp {
  opacity: 0; /* 最初は非表示 */
  transform: translateY(30px); /* 下に30pxの位置から */
  transition: opacity .8s, transform .8s; /* 透過率と縦方向の移動を0.8秒 */
}
/* フェードイン(スクロールした後) */
.js-fadeUp.is-inview {
  opacity: 1; /* 表示領域に入ったら表示 */
  transform: translateY(0); /* 30px上に移動する */
  transition-delay: .5s; /* フェード開始を0.5秒遅らせる */
}
