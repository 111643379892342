body.single-job_search {

  .main-content {
    padding-bottom: 0;
    margin-bottom: 4em;
  }


  .job-single-table-wrap { // main
    margin: 0 -15px;
    @include media-breakpoint-up(md) {
      margin: 0 -30px;
    }
    .item {
      display: flex;
      align-items: center;
      min-height: 4em;
      &:nth-child(even) {
        background-color: #f5f5f5;
      }
    }
    .ttl,
    .desc {
      padding: 20px 10px;
    }
    .ttl {
      width: 9em;
      text-align: center;
    }
    .desc {
      flex: 1;
      border-left: 1px solid $color_light-gray;
    }
    .name-main,
    .name-sub {
      margin: 0;
    }
    .name-sub {
      font-size: 10px;
    }
  }

  ul.job_style {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      &:after{
        content: ' ｜ ';
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }


}