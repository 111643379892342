body.page-id-17,
body.page-id-635,
body.page-id-639 {
  .btn-content {
    text-align: center;
    padding: 1em 0;
    @include media-breakpoint-up(md) {
      padding: 2em 0;
    }

    a.btn {
      display: block;
      @extend %font-lato;
      @include media-breakpoint-up(md) {
        display: inline-block;
        width: 460px;
      }
    }
  }

  #access {
    .ggmap {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 30px;
      height: 0;
      overflow: hidden;
    }

    .ggmap iframe,
    .ggmap object,
    .ggmap embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .row {
      p {
        font-size: 12px;
        @include media-breakpoint-up(md) {
          font-size: 13px;
        }
      }

      .col-md-6 {
        margin-bottom: 1.5em;
      }
    }
  }

  #parking {
    .map {
      margin-bottom: 2em;
      @include media-breakpoint-up(md) {
        margin-bottom: 3em;
      }
    }
    .parking-cont {
      margin-bottom: 2em;
      @include media-breakpoint-up(md) {
        margin-bottom: 3em;
      }
    }
  }
  th,td {
    font-size: 11px;
    text-align: center;
    @include media-breakpoint-up(md) {
      font-size: 13px;
    }
  }
  th {
    font-weight: normal;
    background-color: $color_light-gray;
    white-space: nowrap;
  }
  th[scope="row"] {
    width: 5em;
    @include media-breakpoint-up(md) {
      width: 7em;
    }
  }
  .attention {
    @extend %font-futogo;
    color: #f00;
  }
}