body.page-id-117 {
  .company-info-section {
    @include media-breakpoint-up(md) {
      margin-bottom: 2em;
      &.nakamura {
        margin-top: 4em;
      }
    }
  }
  .img-block {
    text-align: center;
    padding: 3rem 0 2rem;
    border-bottom: 1px solid $color_light-gray;
    @include media-breakpoint-up(md) {
      border-bottom: 0;
      padding: 3rem 0 3rem;
    }
    img {
      max-height: 25vw;
      @include media-breakpoint-up(md) {
        max-height: 150px;
      }
    }
  }

  .nakamura {
    .img-block {
      img {
        max-height: 22vw;
        @include media-breakpoint-up(md) {
          max-height: 120px;
        }
      }
    }
  }
  h3 {
    @extend %font-futogo;
    font-size: 20px;
    @include media-breakpoint-up(md) {
      font-size: 28px;
    }
  }
  .account {
    @extend %font-futogo;
  }
}