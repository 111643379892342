body.page-id-33 {
  table {
    th {
      font-weight: normal;
      background-color: $color_light-gray;
    }
  }
  .main-content {
    @include media-breakpoint-up(md) {
      margin-bottom: 10vh;
    }
  }
}