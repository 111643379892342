body.page-id-734 {
  .main-img {
    text-align: center;

    img {
      @include media-breakpoint-up(md) {
        max-width: 90%;
      }
    }
  }

  .main-content {
    margin-bottom: 2em;
    .entry-header {
      margin-bottom: 0;
    }
    .entry-title {
      margin-bottom: 1em;
    }
  }


  .description {
    border-bottom: 1px solid $color_gray_border;
    padding: 1em 0;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      min-height: 60px;
      .ttl {
        display: flex;
        justify-content: space-between;
        width: 3em;
        margin-right: 2em;
      }
    }
  }

  .section-ttl {
    margin-top: 1.5em;
    padding: .5em 1em;
    @extend %font-lato;
    color: #fff;
    background-color: #9e9e9f;
    font-size: 15px;
    @include media-breakpoint-up(md) {
      margin-top: 2em;
      font-size: 17px;
    }
  }


  .prezent,
  .prezent-more {
    .prize {
      display: flex;
      border-bottom: 1px solid $color_gray_border;
      padding: .5em 0;
      align-items: center;
    }
    .prize-wrap {
      display: flex;
      flex-direction: column;
      line-height: 1.5;
      @include media-breakpoint-up(md) {
        flex: 1;
        font-size: 15px;
        flex-direction: row;
        justify-content: space-between;
      }
    }
    .prize-num {
      display: inline-block;
      text-align: center;
      background-color: #9e9e9f;
      line-height: 30px;
      color: #fff;
      width: 30px;
      height: 30px;
      margin-right: 1em;
      @include media-breakpoint-up(md) {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 15px;
      }
    }
  }

  .other-prezent {
    margin-top: .8em;
  }

  .attention {
    border-top: 1px solid $color_gray_border;
    margin-top: 2em;
    padding-top: 2em;
    margin-bottom: 2em;
    @include media-breakpoint-up(md) {
      margin-bottom: 4em;
    }
  }

  .attention-item {
    margin-bottom: .5em;
    h3,h4 {
      font-size: 14px;
      @include media-breakpoint-up(md) {
        font-size: 15px;
      }
    }
    .max-num {
      @extend %font-futogo;
      font-size: 14px;
      @include media-breakpoint-up(md) {
        font-size: 15px;
      }
    }
  }


}