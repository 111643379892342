.category-nav,
.category-nav-content {
  &.bp-wrap {
    margin-top: 2em;
    @include media-breakpoint-up(md) {
      margin-top: 3em;
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .birthday-present {
    p.every-month {
      text-align: center;
      border-bottom: 1px solid $color_light-gray;
      margin-left: -10px;
      margin-right: -10px;
      padding-bottom: 10px;
      @include media-breakpoint-up(md) {
        font-size: 18px;
        margin-left: -30px;
        margin-right: -30px;
        margin-bottom: 30px;
        padding-bottom: 20px;
      }
    }

    ul {
      justify-content: center;

      li {
        min-width: 40%;
        margin: 0 1em;
        @include media-breakpoint-up(md) {
          min-width: 25%;
        }

        a {
          font-size: 16px !important;
          @include media-breakpoint-up(md) {
            font-size: 20px !important;
          }
        }
      }
    }
  }

  li {
    text-align: center;

    a {
      display: block;
      text-align: center;
      white-space: nowrap;
      @extend %font-lato;
      @extend %font-futogo;
      color: #fff;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.category-nav {
  a {
    background-color: $color_green;
    font-size: 3vw !important;
    @include media-breakpoint-up(md) {
      font-size: 15px !important;
    }
  }
}

.category-nav-content {
  margin-bottom: 2em;
  background-color: #fff;
  border: 1px solid $color_gray_border;
  padding: 10px;
  @include media-breakpoint-up(md) {
    padding: 30px;
    margin-bottom: 3em;
  }

  a {
    margin: .3em;
    font-size: 3vw !important;
    @include media-breakpoint-up(md) {
      font-size: 15px !important;
    }
  }
}

#job_search-cat,
#gourmet_guide-cat {
  li {
    width: 48%;
    @include media-breakpoint-up(md) {
      width: 49%;
    }
  }
}
#shop_search-cat {
  li {
    width: 24%;
    a {
      padding: .5rem 0;
      letter-spacing: 0;
      font-size: 2.7vw !important;
      @include media-breakpoint-up(md) {
        letter-spacing: .1em;
        font-size: 15px !important;

      }
    }
  }
}

#job_search-cat-1,
#shop_search-cat-1,
#cashless-nav,
.sale-hasnav {
  li {
    min-width: 20%;
    @include media-breakpoint-up(md) {
      min-width: 16%;
    }

    &:nth-of-type(4),
    &:nth-of-type(5),
    &:nth-of-type(6) {
      a {
        padding: .5rem 1.4rem;
        @include mq(se) {
          padding: .5rem 1.5rem;
        }
        @include media-breakpoint-up(md) {
          padding: .5rem 1rem;
        }
      }
    }
  }

  a {
    font-size: 2.4vw !important;
    @include mq(se) {
      font-size: 2.7vw !important;
    }
    @include media-breakpoint-up(md) {
      font-size: 15px !important;
    }
  }
}

#cashless-nav {
  li {
    min-width: 20%;
    @include media-breakpoint-up(md) {
      min-width: 33%;
    }
  }
  a {
    font-size: 2.4vw !important;
    @include media-breakpoint-up(md) {
      font-size: 16px !important;
    }
  }
}

#job_search-cat-2 {
  li {
    width: 33%;
    @include media-breakpoint-up(md) {
      width: 16%;
    }
  }
}

#gourmet_guide-cat-1 {
  li {
    min-width: 49%;
  }
}

#gourmet_guide-cat-2,
#shop_search-cat-2 {
  li {
    width: 33%;
    a {
      padding: .5rem;
      font-size: 2.4vw !important;
      @include mq(se) {
        font-size: 2.7vw !important;
      }
      @include media-breakpoint-up(md) {
        font-size: 15px !important;
      }
    }
  }
}

#shop_search-cat-3 {
  @include media-breakpoint-up(md) {
    li {
      width: 9%;
    }
  }
}

#job_search-cat-1 {
  li {
    @include media-breakpoint-up(md) {
      min-width: 14%;
    }
  }
}


nav.before {
  .category-nav-content {
    border: none;
  }
}


nav.fashion {
   .category-nav-content {
     border: none;
     background-color: #d3edfb;
   }
 }

nav.fashion_goods {
  .category-nav-content {
    border: none;
    background-color: #fdeef4;
  }
}


nav.life_style {
  .category-nav-content {
    border: none;
    background-color: #ebf3d9;
  }
}


nav.service {
  .category-nav-content {
    border: none;
    background-color: #edf6f4;
  }
}

nav.food {
  .category-nav-content {
    border: none;
    background-color: #fef6d5;
  }
}

nav.restaurant {
  .category-nav-content {
    border: none;
    background-color: #fffded;
  }
}