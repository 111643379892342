// padding-left ,padding-right
@mixin padding-lr ($size) {
    padding-left: $size;
    padding-right: $size;
}

// margin-left ,margin-right
@mixin margin-lr ($size) {
    margin-left: $size;
    margin-right: $size;
}



@include keyframes(flash){
    0% {
        opacity: .4;
    }
    100% {
        opacity: 1;
    }
}


@include keyframes(flash-w){
    0% {
        margin-left: -100%;
        opacity: 1;
    }
    99% {
        opacity: 1;
    }
    100% {
        margin-left: 100%;
        opacity: 0;
    }
}

@include keyframes(cut_in){
    0% {
        opacity: 0;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


/*gridのgutter調整*/
.row-0{
    margin-left:0px;
    margin-right:0px;

    > div,
    > article {
        padding-right:0px;
        padding-left:0px;
    }
}
.row-10{
    margin-left:-5px;
    margin-right:-5px;

    > div,
    > article {
        padding-right:5px;
        padding-left:5px;
    }
}
.row-20{
    margin-left:-10px;
    margin-right:-10px;

    > div,
    > article {
        padding-right:10px;
        padding-left:10px;
    }
}
.row-30{
    margin-left:-15px;
    margin-right:-15px;

    > div,
    > article {
        padding-right:15px;
        padding-left:15px;
    }
}
.row-40{
    margin-left:-20px;
    margin-right:-20px;

    > div,
    > article {
        padding-right:20px;
        padding-left:20px;
    }
}


$color_navy :#326698;
$color_green :#64bbba;
$color_green_hover :#a6d7ce;
$color_green_active: #0bb3b3;

$color_light-green :#a6d7ce;
$color_light-gray :#f0f0f0;

$color_gray_txt: #afafaf;
$color_gray_border: #e9e9e9;
$color_gray_border_dark: #595757;




$color_purple :#767cae;

$color_fashion: #718ec8;
$color_fashion_hover: #899fcc;
$color_fashion_active: #577dc9;

$color_fashion_goods: #be7ab1;
$color_fashion_goods_hover: #bd88b3;
$color_fashion_goods_active: #bd60ab;

$color_life_style: #98ca60;
$color_life_style_hover: #adc98d;
$color_life_style_active: #85c938;

$color_service: #2fb6a7;
$color_service_hover: #6fada6;
$color_service_active: #0da897;

$color_food: #f6cc00;
$color_food_hover: #f5e182;
$color_food_active: #e3bd02;

$color_restaurant: #f39d50;
$color_restaurant_hover: #f7be8b;
$color_restaurant_active: #f58622;

$color_birthday: #64bbba;
$color_birthday_hover: #a6d7ce;
$color_birthday_active: #a6d7ce;
$color_birthday_disable: #d0d7d6;

$color_btn_gray: #9fa0a0;
$color_btn_gray_hover: #cfd1d1;
$color_btn_gray_active: #868787;

