body.page-template-sale-fair-hasnav-hasmovie-tmpl {
  .youtube-cm {
    background-color: #000;
    @include media-breakpoint-up(md) {
      //padding: 3em 0;
    }
  }

  .youtube-cm-wrap {
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    @include media-breakpoint-up(md) {
      //max-width: 640px;
    }
  }

  .youtube-cm-inner {
    position: relative;
    width: 100%;
    padding-top: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }

  }
}