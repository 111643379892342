// positioning
// ===========

///*
//use ex
//.hoge {
//  @include mq(sm) {
//    if you need
//  }
//}
//*/
$se: 321px;

// styleguide:ignore:start
@mixin mq($media) {
  @if $media == se {
    @media screen and (min-width: #{$se}) {
      @content;
    }
  }
}


// vertical
@mixin centering-vertical($height: 0) {
  top: 50%;
  transform: translateY(-50%);
  @if not ($height == 0) {
    .no-csstransforms & {
      margin-top: -($height / 2);
    }
  }
}

// horizontal
@mixin centering-horizontal($width: 0) {
  left: 50%;
  transform: translateX(-50%);
  @if not ($width == 0) {
    .no-csstransforms & {
      margin-left: -($width / 2);
    }
  }
}

// both
@mixin centering-both($height: 0, $width: 0) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @if not ($width == 0) or not ($height == 0) {
    .no-csstransforms & {
      @if not ($height == 0) {
        margin-top: -($height / 2);
      }
      @if not ($width == 0) {
        margin-left: -($width / 2);
      }
    }
  }
}

// clearfix
%clearfix {
  &:after {
    overflow: hidden;
    content: "";
    display: block;
    clear: both;
  }
}

// font-size
@mixin fz($size) {
  font-size: $size + px;
  font-size: ($size / 16) + rem;
}

// transition10
@mixin transition10 {
  transition: all 1s ease;
}

// transition05
@mixin transition05 {
  transition: all .5s ease;
}

// transition03
@mixin transition03 {
  transition: all .3s ease;
}

// keyframes animation
@mixin keyframes($animation-name: animaton) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($animation-name) {
  -webkit-animation: $animation-name;
  -moz-animation: $animation-name;
  animation: $animation-name;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}

%sprite-replace {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.pc-cont {
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }
}

.sp-cont {
  display: block;
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

.pc-cont-inline {
  display: none;
  @include media-breakpoint-up(md) {
    display: inline;
  }
}

.sp-cont-inline {
  display: inline;
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}