.img-block {
  a {
    img {
      @include transition05;
    }
    &:hover {
      img {
        opacity: .6;
      }
    }
  }
}

.row-eq-height {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  @include media-breakpoint-up(md) {
    &:after {
      content: '';
      width: 32.3%;
      display: block;
    }
  }
}

article.entry-item {
  background-color: #fff;
  margin-bottom: 1em;
  width: 48%;
  @include media-breakpoint-up(md) {
    width: 32.3%;
    margin-bottom: 2em;
  }
  .img-block {
    position: relative;
    overflow: hidden;
    height: 27.8vw;
    @include media-breakpoint-up(sm) {
      height: 154px;
    }
    @include media-breakpoint-up(md) {
      height: 140px;
    }
    @include media-breakpoint-up(lg) {
      height: 189px;
    }
    @include media-breakpoint-up(xl) {
      height: 236px;
    }
    img {
      min-width: 100%;
      position: absolute;
      @include centering-both();
    }
  }

  .txt-block {
    border-top: 0;
    padding: .5em;
    border-top: 1px solid $color_gray_border;
    @include media-breakpoint-up(md) {
      padding: 1em 1.5em;
    }
  }
  .sub-ttl {
    font-size: 11px;
    color: $color_green;
    margin-bottom: 1em;
    line-height: 1.2;
    @include media-breakpoint-up(md) {
      font-size: 13px;
    }
  }
  .entry-title {
    @extend %font-futogo;
    font-size: 13px;
    margin-bottom: .6em;
    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
  .entry-meta {
    line-height: 1.2;
    .posted-on {
      @extend %font-lato;
      color: $color_gray_txt;
      font-size: 10px;
      @include media-breakpoint-up(md) {
        font-size: 13px;
      }
    }
  }
}

#right-sidebar {
  .other-blog-entry {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}


article.recentry-post {
  width: 48%;
  margin-bottom: 1em;
  background-color: #fff;
  @include media-breakpoint-up(md) {
    margin-bottom: 2em;
    width: 100%;
  }
  .txt-block {
    border-top: 0;
    padding: .5em;
    border-top: 1px solid $color_gray_border;
    @include media-breakpoint-up(md) {
      padding: 1em 1.5em;
    }
  }
  .sub-ttl {
    font-size: 11px;
    color: $color_green;
    margin-bottom: .3em;
    @include media-breakpoint-up(md) {
      font-size: 13px;
    }
  }
  .entry-title {
    @extend %font-futogo;
    font-size: 13px;
    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }
  .entry-meta {
    .posted-on {
      @extend %font-lato;
      color: $color_gray_txt;
      font-size: 10px;
      @include media-breakpoint-up(md) {
        font-size: 13px;
      }
    }
  }
}

