body.page-id-724 {
  .main-content {
    margin-bottom: 2em;
  }
  .discription-block {
    margin-top: 2em;
    @include media-breakpoint-up(md) {
      margin-top: 3em;
    }
    .p-1 {
      margin-bottom: 0;
    }
  }
  .day15,.day5 {
    border-bottom: 1px solid $color_gray_border;
  }
  section.day5,
  section.day15,
  section.day25 {
    padding: 2em 0;
    @include media-breakpoint-up(md) {
      padding: 3em 0;
    }
  }
  .day25,.day15 {
    section {
      margin-top: 2em;
    }
  }
  .ttl-style-1 {
    margin-top: 1em;
    text-align: center;
    @extend %font-futogo;
    font-size: 15px;
    @include media-breakpoint-up(md) {
      font-size: 21px;
    }
    &:before {
      display: inline-block;
      margin: 0 .5em;
      content: '＼';
    }
    &:after {
      display: inline-block;
      margin: 0 .5em;
      content: '／';
    }
  }

  .p-2 {
    text-align: center;
    line-height: 1.4;
    @extend %font-futogo;
    font-size: 18px;
    @include media-breakpoint-up(md) {
      font-size: 26px;
    }
  }

  .ttl-style-2 {
    text-align: center;
    p {
      min-width: 60%;
      padding: .2em 0;
      border-radius: .3em;
      @extend %font-futogo;
      position: relative;
      background-color: #fff100;
      display: inline-block;
      font-size: 21px;
      @include media-breakpoint-up(md) {
        min-width: 300px;
        font-size: 30px;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: -20px;
        @include centering-horizontal();
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 10px 0 10px;
        border-color: #fff100 transparent transparent transparent;
      }
    }
    &.green {
      p {
        background-color: #74b8b5;
        &:after {
          border-color: #74b8b5 transparent transparent transparent;
        }
      }
    }
    &.pink {
      p {
        color: #fff;
        background-color: #e62e8b;
        &:after {
          border-color: #e62e8b transparent transparent transparent;
        }
      }
    }
  }

  .ttl-style-3 {
    text-align: center;
    p {
      position: relative;
      display: inline-block;
      padding: .2em 0;
      min-width: 40%;
      font-size: 21px;
      border: 1px solid #000;
      @include media-breakpoint-up(md) {
        min-width: 200px;
        font-size: 30px;
      }
      &:after {
        position: absolute;
        bottom: -11px;
        @include centering-horizontal();
        content: '↓';
        line-height: 0;
        font-size: 30px;
      }
    }
    &.pink {
      p {
        color: #e62e8b;
        border-color: #e62e8b;
      }
    }
    &.green {
      p {
        color: #74b8b5;
        border-color: #74b8b5;
      }
    }
  }

  .point {
    line-height: 1.2;
    text-align: center;
    @extend %font-lato;
    font-weight: 900;
    color: #b60033;
    font-size: 17vw;
    @include media-breakpoint-up(md) {
      font-size: 140px;
    }
    span {
      vertical-align: middle;
      font-size: 6vw;
      @include media-breakpoint-up(md) {
        font-size: 50px;
      }
    }
  }

  .bnr-block {
    a {
      &:hover {
        opacity: .6;
      }
    }
  }

  .sns-btns {
    margin-top: 2em;
    @include media-breakpoint-up(md) {
      margin-top: 4em;
    }
  }


  section.day5 {
    position: relative;
    //&:before {
    //  position: absolute;
    //  z-index: 10;
    //  display: block;
    //  content: '';
    //  width: 100px;
    //  height: 100px;
    //  background: url("https://sasebo-5bangai.com/shoplist/wp-content/uploads/2020/03/start.png") no-repeat;
    //  background-size: contain;
    //  top: -2px;
    //  left: 5px;
    //  @include media-breakpoint-up(md) {
    //    width: 150px;
    //    height: 150px;
    //    left: 60px;
    //  }
    //}
  }


}