body {
  color: #000;
  background-color: #f5f5f5;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1290px;
  }
}


a {
  color: #000;
  @include transition05;

  &:hover {
    color: #999;
  }
}

#main {
  line-height: 2;
  font-size: 13px;
}

#index-wrapper,
#error-404-wrapper,
#search-wrapper,
#archive-wrapper,
#single-wrapper,
#page-wrapper,
#full-width-page-wrapper {
  padding-top: 55px;
  @include media-breakpoint-up(md) {
    padding-top: 100px;
  }
}

.entry-title {
  line-height: 1.4;
  font-size: 18px;
  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
}

.breadcrumbs {
  padding-top: 2em;
  color: #898989;
  font-size: 13px;
  @include media-breakpoint-up(md) {
    padding-top: 4em;
    font-size: 10px;
  }
}
