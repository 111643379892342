.content-header {
  padding: 1em 0 1.5em;
  @include media-breakpoint-up(md) {
    padding: 2em 0;
  }
  h1 {
    margin-bottom: 0;
    @extend %font-lato;
    font-weight: 700;
  }
  .ttl-description {
    font-size: 9px;
    margin-top: .4em;
    margin-bottom: 0;
    letter-spacing: .1em;
    @include media-breakpoint-up(md) {
      font-size: 13px;
    }
  }
}