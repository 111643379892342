.main-content {
  background-color: #fff;
  padding: 1px 15px 15px;
  @include media-breakpoint-up(md) {
    padding: 1px 30px 30px;
  }

  ul {
    margin-bottom: 0;
  }

  .page-section {
    border-bottom: 1px solid $color_light-gray;
    margin-bottom: 2em;
    @include media-breakpoint-up(md) {
      margin-bottom: 3em;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  // original headers

  .section-header-h2 {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 16px;
    @extend %font-futogo;
    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }

  .section-header-big-h3 {
    @extend %font-futogo;
    font-size: 16px;
    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  .section-header-box-h3 {
    @extend %font-futogo;
    color: #fff;
    font-size: 16px;
    padding: .5em;
    margin-bottom: 1em;
    @include media-breakpoint-up(md) {
      font-size: 18px;
      padding: .5em 1em;
    }

    &.green {
      background-color: #87bfbc;
    }

    &.purple {
      background-color: $color_purple;
    }

    &.gray {
      background-color: #9e9e9f;
    }
  }

  dl.sp-side {
    border-top: 1px solid $color_light-gray;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    @include media-breakpoint-up(md) {
      border-bottom: 0;
      width: 372px;
      margin-bottom: 1rem;
      padding-bottom: 0;
    }

    dt {
      font-weight: normal;
      display: block;
      padding: .5rem 0;
      float: left;
      width: 6em;
      border-bottom: 1px solid $color_light-gray;
      @include media-breakpoint-up(md) {
        padding-bottom: 0;
        border-bottom: 0;
        float: none;
        width: auto;
      }
    }

    dd {
      padding: .5rem 0;
      margin-bottom: 0;
      margin-left: 6em;
      border-bottom: 1px solid $color_light-gray;
      @include media-breakpoint-up(md) {
        padding-top: 0;
        margin-left: 0;
      }
    }
  }

  dl.pc-side {
    border-top: 1px solid $color_light-gray;
    @include media-breakpoint-up(md) {
      border-top: 0;
      border-bottom: 1px solid $color_light-gray;
      overflow: hidden;
      width: 100%;
      margin-bottom: 1rem;
      padding-bottom: 0;
    }

    dt {
      font-weight: normal;
      padding: .5rem 0 .3rem;
      border-bottom: 0;
      float: none;
      width: auto;
      @include media-breakpoint-up(md) {
        border-top: 1px solid $color_light-gray;
        display: block;
        padding: 1.5rem 0 1rem;
        float: left;
        width: 6em;
      }
    }

    dd {
      padding-top: 0;
      padding-bottom: .5rem;
      margin-left: 0;
      border-bottom: 1px solid $color_light-gray;
      @include media-breakpoint-up(md) {
        border-bottom: 0;
        border-top: 1px solid $color_light-gray;
        padding: 1.5rem 0 1rem;
        margin-bottom: 0;
        margin-left: 6em;
        &:after {
          content: '';
          display: block;
          clear: both;
          line-height: 0;
          height: 1px;
        }
      }
    }
  }

  .img-block {
    margin-bottom: 1rem;
  }

  .table-bordered td, .table-bordered th {
    border: 1px solid #e1e1e1;
    vertical-align: middle;
  }


}

.site {
  // btn
  .btn {
    border-radius: 0;
    border: 0;
    @extend %font-lato;
    font-size: 15px;
  }

  .btn-success {
    background-color: $color_green;

    &:hover {
      background-color: $color_light-green;
    }

    &:active,
    &.active {
      background-color: $color_green_active !important;
    }
  }

  .btn-gray {
    background-color: $color_btn_gray;

    &:hover {
      background-color: $color_btn_gray_hover;
    }

    &:active,
    &.active {
      background-color: $color_btn_gray_active !important;
    }
  }

  .btn-fashion {
    background-color: $color_fashion;

    &:hover {
      background-color: $color_fashion_hover;
    }

    &:active,
    &.active {
      background-color: $color_fashion_active !important;
    }
  }

  .btn-fashion_goods {
    background-color: $color_fashion_goods;

    &:hover {
      background-color: $color_fashion_goods_hover;
    }

    &:active,
    &.active {
      background-color: $color_fashion_goods_active !important;
    }
  }

  .btn-life_style {
    background-color: $color_life_style;

    &:hover {
      background-color: $color_life_style_hover;
    }

    &:active,
    &.active {
      background-color: $color_life_style_active !important;
    }
  }

  .btn-service {
    background-color: $color_service;

    &:hover {
      background-color: $color_service_hover;
    }

    &:active,
    &.active {
      background-color: $color_service_active !important;
    }
  }

  .btn-food {
    background-color: $color_food;

    &:hover {
      background-color: $color_food_hover;
    }

    &:active,
    &.active {
      background-color: $color_food_active !important;
    }
  }

  .btn-restaurant {
    background-color: $color_restaurant;

    &:hover {
      background-color: $color_restaurant_hover;
    }

    &:active,
    &.active {
      background-color: $color_restaurant_active !important;
    }
  }

  .btn-birthday {
    background-color: $color_birthday;

    &:hover {
      background-color: $color_birthday_hover;
    }

    &:active,
    &.active {
      background-color: $color_birthday_active !important;
    }

    &.disabled-btn {
      background-color: $color_birthday_hover;
    }
  }

  .disabled-btn {
    pointer-events: none;
  }




  .sns-btns {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin-right: 1em;
        margin-bottom: 1em;
        font-size: 10px;
        @include media-breakpoint-up(md) {
          font-size: 13px;
        }

        i {
          color: #fff;
          display: inline-block;
          min-width: 30px;
          min-height: 30px;
          position: relative;
          font-size: 20px;
          vertical-align: middle;
          margin-right: .3em;
          @include media-breakpoint-up(md) {
            min-width: 40px;
            min-height: 40px;
          }

          &:before {
            position: absolute;
            @include centering-both();
          }
        }

        &.facebook {
          i {
            background-color: #3b5998;
          }

          a {
            color: #3b5998;
          }
        }

        &.tweet {
          i {
            background-color: rgb(29, 161, 242);
          }

          a {
            color: rgb(29, 161, 242);
          }
        }

        &.instagram {
          i {
            background-color: #f00075;
          }

          a {
            color: #f00075;
          }
        }

      }

      a {
        @extend %font-lato;

        &:hover {
          opacity: .6;
        }
      }
    }
  }


  #item-more-wrap,
  .item-more-wrap {
    margin-top: 1em;
    text-align: center;
  }

  #item-more,
  .item-more {
    min-width: 30%;
    @include media-breakpoint-up(md) {
      min-width: 380px;
    }
  }

  #now-loading {
    padding-top: 2em;
    text-align: center;
  }


}


.main-content,
section.blog-post-wrap {
  .entry-title {
    font-size: 17px;
    @extend %font-futogo;
    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }
}


.footer-shop-btn {
  position: fixed;
  bottom: 0;
  @include centering-horizontal();
  z-index: 999;
  button {
    width: 100vw;
  }
}
