
%font-lato {
  font-family: 'Lato', sans-serif;
  letter-spacing: .1em;
}

%font-futogo {
  font-family: a-otf-futo-go-b101-pr6n, sans-serif;
  font-weight: 500;
  font-style: normal;
}

%font-mb31 {
  font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
  font-weight: 600;
  font-style: normal;
}