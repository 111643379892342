body.page-template-shop-tmpl {
  .main-content {
    padding-bottom: 0;
  }

  .shop-main-ph {
    width: auto;
    height: 200px;
    text-align: center;
    position: relative;
    overflow-x: hidden;
    @include media-breakpoint-up(md) {
      width: 100%;
      height: auto;
    }
    img {
      position: absolute;
      @include centering-horizontal();
      width: auto;
      max-width: 492px;
      height: 100%;
      @include media-breakpoint-up(md) {
        position: relative;
        left: auto;
        transform: none;
        height: auto;
        max-width: 100%;
      }
    }
  }

  .shop-information-wrap {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
      .logo-name-block {
        width: 380px;
      }
      .discription-block {
        flex: 1;
      }
    }
  }

  .logo-block {
    text-align: center;
    height: 101px;
    padding-bottom: 1px;
    border-bottom: 1px solid $color_gray_border;
    @include media-breakpoint-up(md) {
      position: relative;
      height: 240px;
    }
    img {
      height: 100px;
      @include media-breakpoint-up(md) {
        position: absolute;
        @include centering-both();
        width: 350px;
        height: auto;
        margin-left: -15px;
      }
    }
  }

  .shop-name-information {
    @extend %font-futogo;
    padding: 1em 0;
    border-bottom: 1px solid $color_gray_border;
    @include media-breakpoint-up(md) {
      padding: 2em 0;
      border-bottom: none;
    }
    h2 {
      font-size: 20px;
      margin-bottom: 0;
    }
    p {
      font-size: 13px;
      margin-bottom: 0;
      line-height: 1.8;
    }
    .name-sub {
      @extend %font-lato;
      margin-bottom: .8em;
    }
    .catname-en {
      @extend %font-lato;
    }
  }

  .discription-block {
    @include media-breakpoint-up(md) {
      border-left: 1px solid $color_gray_border;
    }
  }

  .shop-desc-item {
    border-bottom: 1px solid $color_gray_border;
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      padding-left: 2em;
      flex-direction: row;
      min-height: 60px;
      .ttl {
        width: 9em;
      }
    }
    &:last-child {
      border-bottom: none;
      display: block;
    }
    .floor {
      span {
        display: block;
        @include media-breakpoint-up(md) {
          display: inline-block;
          margin-right: 2em;
        }
      }
    }
  }

  .cashless-wrap {
    margin: 2em 0;
    @include media-breakpoint-up(md) {
      margin: 3em 0;
    }
    .cashless-description,
    .cashless-note {
      > p {
        margin-bottom: 0;
        padding: 1em 0;
      }
    }
    .cashless-note {
      > p {
        font-size: 10px;
        border-top: 1px solid #e9e9e9;
      }
    }
    .cashless-cat {
      padding-bottom: 1em;
      border-top: 1px solid #e9e9e9;
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
      }
      li {
        margin: 1em 1.5em 0 0;
      }
      img {
        height: 25px;
        @include media-breakpoint-up(md) {
          height: 38px;
        }
      }
    }
  }

  .shop-blog-wrap {
    .content-header {
      @include media-breakpoint-up(md) {
        padding-top: 3em;
        padding-bottom: 3em;
      }
    }
  }

  .open-close-info {
    p {
      background-color: $color_green;
      @extend %font-futogo;
      color: #fff;
      text-align: center;
      margin-bottom: 0;
      padding: 1em 0;
      font-size: 10px;
      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }
  }
}