body.page-id-21 {
  .main-content {
    background-color: transparent;
  }

  .job-search-result-wrap {
    margin: 0 -15px;
    @include media-breakpoint-up(md) {
      margin: 0 -30px;
    }
  }

  .job-search-item {
    margin-bottom: 1em;
    background-color: #fff;
    border: 1px solid $color_gray_border;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
    .logo-block {
      display: none;
      a {
        &:hover {
          opacity: .6;
        }
      }
      @include media-breakpoint-up(md) {
        display: block;
        img {
          width: 204px;
        }
      }
    }

    .shop-block {
      padding: 15px;
      border-bottom: 1px solid $color_gray_border;
      @include media-breakpoint-up(md) {
        border-bottom: 0;
        border-left: 1px solid $color_gray_border;
        min-width: 380px;
        padding: 23px 30px;
      }
      a {
        display: flex;
        flex-direction: column-reverse;
        @include media-breakpoint-up(md) {
          flex-direction: column;
        }
        &:hover {
          text-decoration: none;
        }
      }
      .job_shop_name {
        margin: 0;
        font-size: 13px;
        @include media-breakpoint-up(md) {
          font-size: 20px;
        }
      }
      .job_shop_sub_name {
        margin: 0;
        display: none;
        @include media-breakpoint-up(md) {
          display: block;
        }
      }
      .job_shop_cat {
        margin: 0;
        color: $color_gray_txt;
        font-size: 10px;
        @include media-breakpoint-up(md) {
          font-size: 14px;
        }
      }
    }

    .job-block {
      padding: 15px;
      @include media-breakpoint-up(md) {
        flex: 1;
        border-left: 1px solid $color_gray_border;
      }
      a {
        &:hover {
          text-decoration: none;
        }
      }
      .job_cat {
        margin: 0;
        line-height: 1.4;
        @include media-breakpoint-up(md) {
          font-size: 18px;
        }
      }
      .job_style {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
          display: inline-block;
          font-size: 10px;
          @include media-breakpoint-up(md) {
            font-size: 14px;
          }
          &:after {
            content: ' ｜';
          }
          &:last-child {
            &:after {
              content: none;
            }
          }
        }
      }
      .job_post_time {
        margin: 0;
        color: $color_gray_txt;
        font-size: 10px;
        @include media-breakpoint-up(md) {
          font-size: 14px;
        }
      }
    }
  }
}