$bp-color: #d865a2;

#main {
  &.specialcoupon,
  &.birthday-present {


    .bp-read {
      margin: 0 0 1.5em 0;
      padding: 1.5em 1em .5em;
      text-align: center;
      background-color: #fff;

      h3 {
        font-weight: bold;
        color: $bp-color;
        font-size: 15px;
        @include media-breakpoint-up(md) {
          font-size: 20px;
        }
      }

      p {
        text-align: justify;
        @include media-breakpoint-up(md) {
          text-align: center;
        }
      }
    }


    .bp-nav-set {
      dl {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        background-color: #fff;
        padding: 1em 1em 0 1em;
      }

      dt {
        color: $bp-color;
        margin-right: 1em;
      }

      dd {
        margin: 0;
      }
    }


    .type-specialcoupon,
    .type-birthday-present {
      margin-bottom: 1em;
      background-color: #fff;
      border: 1px solid $color_gray_border;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: center;
        width: 100%;
      }

      .img-block {
        text-align: center;
        border-bottom: 1px solid $color_gray_border;
        @include media-breakpoint-up(md) {
          border-bottom: 0;
          width: 315px;
        }

        img {
          max-width: 50%;
          @include media-breakpoint-up(md) {
            max-width: 250px;
          }
        }
      }

      .txt-block {
        padding: 20px 15px 15px;
        @include media-breakpoint-up(md) {
          flex: 1;
          border-left: 1px solid $color_gray_border;
          padding-left: 25px;
        }

        .prezent-target {
          p {
            @extend %font-futogo;
            font-size: 16px;
            line-height: 1.5;

            @include media-breakpoint-up(md) {
              font-size: 20px;
              line-height: 2;
            }

            margin-bottom: .2em;
          }
        }
        .prezent-body {
          @extend %font-futogo;
          font-size: 16px;
          line-height: 1.5;

          @include media-breakpoint-up(md) {
            font-size: 20px;
            line-height: 2;
          }

          ul {
            padding-left: 1em;
            margin-bottom: .2em;
            list-style-type: none;
          }

          li {
            font-size: 18px;

            @include media-breakpoint-up(md) {
              font-size: 20px;
            }

            &:before {
              content: '●';
              color: $bp-color;
              margin-right: .5em;
              margin-left: -1em;
            }
          }

        }

        .attention {
          ul {
            padding-left: 1em;
            list-style-type: none;
          }

          li {
            line-height: 1.4;
            &:before {
              content: '※';
              margin-right: .5em;
              margin-left: -1em;
            }
          }
        }

        .space {
          display: flex;
          margin-right: -15px;
          margin-left: -15px;
          border-top: 1px solid $color_gray_border;
          margin-bottom: 0;
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 15px;
          @include media-breakpoint-up(md) {
            margin-left: -25px;
            padding-left: 25px;
          }

          dt {
            color: $bp-color;
            margin-right: 1em;
          }
        }

        .coupon-img {
          dt {
            display: none;
          }
          dd {
            a {
              text-decoration: underline;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }

    .category-section {
      margin-top: -1em;
      margin-bottom: 2em;
    }

    .section-header-box-bp {
      color: #fff;
      padding: .5em 1em;
      font-family: "Lato", sans-serif;
      letter-spacing: 0.1em;
      font-size: 18px;
      @include media-breakpoint-up(md) {
        font-size: 20px;
      }
      &.fashion {
        background-color: #718ec8;
      }
      &.fashion-goods {
        background-color: #be7ab1;
      }
      &.life-style {
        background-color: #98ca60;
      }
      &.service {
        background-color: #2fb6a7;
      }
      &.food {
        background-color: #f6cc00;
      }
      &.restaurant {
        background-color: #f39d50;
      }
    }

  }
}


body.single-specialcoupon {
  .mfp-close {
    font-family: "Lato", sans-serif;
    letter-spacing: 0.1em;
    font-size: 44px;
    color: #fff;
  }

  .movie {
    display: none;
  }
}