#wrapper-footer {
  color: #fff;
  background-color: $color_green;
  @include media-breakpoint-up(md) {
    padding-bottom: 0;
  }

  .site-footer {
    .quote-copy {
      p {
        font-size: 14px;
        @include media-breakpoint-up(md) {
          font-size: 14px;
        }
      }
    }
  }

  .shop-hour {
    @include media-breakpoint-up(md) {
      max-width: 800px;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
    }

    li {
      white-space: nowrap;

      &:nth-child(odd) {
        min-width: 200px;
      }

      &:nth-of-type(1),
      &:nth-of-type(4) {
        @include media-breakpoint-up(lg) {

          width: 224px;
        }
      }

      &:nth-of-type(2),
      &:nth-of-type(5) {
        @include media-breakpoint-up(lg) {
          min-width: auto;
          width: 180px;
        }
      }

      &:nth-of-type(3),
      &:nth-of-type(6) {
        @include media-breakpoint-up(lg) {
          width: 220px;
        }
      }
    }

    dl {
      overflow: hidden;
      margin-bottom: 0;
      display: flex;
    }

    dt {
      margin-right: .5em;
      font-weight: normal;
    }

    dt, dd {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 1.8;
      @include media-breakpoint-up(md) {
        font-size: 13px;
      }
    }

    .ohter {
      line-height: 1.8;
      font-size: 12px;
      @include media-breakpoint-up(md) {
        font-size: 13px;
      }
    }
  }

  .foot-logo {
    .foot-logo-block {
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    .txt-block {
      margin: 1.8em 0;

      a {
        color: #fff;
      }

      h2 {
        font-weight: normal;
        font-size: 16px;
        @include media-breakpoint-up(md) {
          font-size: 14px;
        }
      }

      .addr, .tel {
        font-size: 12px;
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
          font-size: 14px;
        }
      }
    }

    img {
      width: 112px;
      height: 63px;
    }

    .txt-block, .foot-logo-block {
      @include media-breakpoint-up(xl) {
        padding-left: 54px;
      }
    }
  }
}

#nav_menu-2,
#nav_menu-3 {
  padding: 0;
  display: block;
  max-width: 100%;
}

#menu-footernav {
  list-style-type: none;
  padding: 0;
  margin-top: 2em;
  margin-bottom: 2em;

  display: flex;
  flex-wrap: wrap;
  max-width: 283px;
  @include media-breakpoint-up(lg) {
    max-width: 816px;
  }

  li {
    white-space: nowrap;
    font-size: 12px;
    line-height: 2;
    @include media-breakpoint-up(lg) {
      font-size: 13px;
    }

    &:nth-child(even) {
      min-width: 130px;
      @include media-breakpoint-up(lg) {
        min-width: auto;
      }
    }

    &.menu-item-78 {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &:nth-of-type(1),
    &:nth-of-type(7) {
      @include media-breakpoint-up(lg) {
        min-width: 88px;
      }
    }

    &:nth-of-type(2),
    &:nth-of-type(8) {
      @include media-breakpoint-up(lg) {
        min-width: 138px;
      }
    }

    &:nth-of-type(3),
    &:nth-of-type(9) {
      @include media-breakpoint-up(lg) {
        min-width: 115px;
      }
    }

    &:nth-of-type(4),
    &:nth-of-type(10) {
      @include media-breakpoint-up(lg) {
        min-width: 115px;
      }
    }

    &:nth-of-type(5),
    &:nth-of-type(11) {
      @include media-breakpoint-up(lg) {
        min-width: 170px;
      }
    }

    &:nth-of-type(6),
    &:nth-of-type(12) {
      @include media-breakpoint-up(lg) {
        min-width: 150px;
      }
    }

    a {
      color: #fff;
      display: inline-block;
      @include transition05;

      &:hover {
        text-decoration: none;
        color: #efefef;
      }

      &:before {
        content: '>';
        margin-right: .5em;
      }
    }
  }
}


#menu-footernav2 {
  margin-bottom: 1em;
}

.site-info {
  font-size: 10px;
  @extend %font-lato;
  font-weight: 400;
  @include media-breakpoint-up(md) {
    font-size: 12px;
    text-align: right;
    margin-top: -3em;
  }

  .auther-dashboad {
    margin-left: 2em;

    a {
      color: #efefef;
    }
  }
}
