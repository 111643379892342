body.parent-pageid-17 {
  section.month {
    padding: 15px 0;
    @include media-breakpoint-up(md) {
      padding: 30px 0;
    }
  }
  table {
    width: 100%;
    margin-bottom: 1em;
  }
  th,td {
    text-align: center;
    border: 1px solid $color_light-gray;
    @include media-breakpoint-up(md) {
      height: 4em;
    }
  }
  th.sat {
    color: #1e6abc;
  }
  th.sun {
    color: #b52727;
  }
  .time11-19 {
    background-color: #fff100;
  }
  .time12-19 {
    background-color: #e79bc1;
  }
  .time10-19 {
    background-color: #e79bc1;
  }
  p.note {
    font-size: 11px;
    padding-bottom: 3em;
    border-bottom: 1px solid $color_light-gray;
    @include media-breakpoint-up(md) {
      font-size: 13px;
    }
    span {
      display: inline-block;
      width: 3em;
      margin-right: .2em;
    }
  }
}