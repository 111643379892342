body.home {
  overflow-x: hidden;

  #full-width-page-wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }

  #main > section {
    margin-bottom: 2em;
    @include media-breakpoint-up(md) {
      margin-bottom: 5em;
    }
  }

  #top-slider {
    width: 100vw;
    padding-top: 55px;
    @include transition03;
    opacity: 0;
    overflow-x: hidden;
    @include media-breakpoint-up(md) {
      padding-top: 100px;
    }

    .slide {
      a {
        img {
          @include transition05;
        }

        &:hover {
          img {
            opacity: .6;
          }
        }
      }
    }

    &.slick-initialized {
      opacity: 1;
    }

    img {
      width: 100vw;
      @include media-breakpoint-up(md) {
        max-width: 750px;
      }
    }

    .slick-list {
      margin-right: -4px;
    }

    .slick-slide {
      margin-right: 4px;
    }

    .slick-next {
      @include media-breakpoint-up(md) {
        right: 15px;
      }
    }

  }

  #top-sub-slider {
    margin-top: 30px;
    @include media-breakpoint-up(md) {
      margin-left: 2px;
    }
    .slick-slide {
      margin-right: 4px;
    }

    .slide {
      a {
        img {
          @include transition05;
        }

        &:hover {
          img {
            opacity: .6;
          }
        }
      }

    }
  }




  .ticker ul {
    padding-left: 0;
    list-style-type: none;
  }

  .ticker ul li {
    width: 100%;
  }


  #urgent-news-ticker {
    background-color: #fff;
    border: 4px solid #dc3545;
    padding: .8em 1.5em;
    @include media-breakpoint-up(md) {
      padding: .8em 3em 1em;
      text-align: center;
    }
    a {
      &:hover {
        text-decoration: none;
        opacity: .8;
      }
    }
    ul {
      margin: 0;
      @extend %font-futogo;

    }
    .ttl {
      color: #dc3545;
      font-size: 18px;
    }
  }

  #main section.urgent-news {
    margin-top: 2em;
    margin-bottom: 1em;
  }
  .gobangai-news-wrap {
    margin-top: 2em;
    .entry-title,
    .ttl-description {
      display: none;
    }

    #news-ticker {


      ul {
        margin: 0;
        @extend %font-futogo;
        @include media-breakpoint-up(md) {
        }
        li {
          padding: .8em 1.5em;
          margin-bottom: .8em;
          background-color: #fff;
          @include media-breakpoint-up(md) {
            padding: .8em 3em 1em;
          }
        }
      }

      .entry-meta {
        @extend %font-lato;
        display: block;
        @include media-breakpoint-up(md) {
          display: inline-block;
          margin-right: 1.5em;
        }
      }

      .news-label {
        color: #b60033;
        margin-right: 1em;
      }
    }
  }


  .pop_gallery-content {
    img {
      @include transition05;
    }

    a {
      &:hover {
        img {
          opacity: .6;
        }
      }
    }

    .slick-list {
      margin-right: -10px;
      @include media-breakpoint-up(md) {
        margin-right: -30px;
      }
    }

    .slick-slide {
      margin-right: 10px;
      @include media-breakpoint-up(md) {
        margin-right: 30px;
      }
    }

    .pop_gallery-slide {
      background-color: #fff;
      min-height: 290px;
      @include media-breakpoint-up(md) {
        min-height: 388px;
      }
    }

    .txt-block {
      //background-color: #fff;
    }

    p {
      padding: 10px;
      font-size: 10px;
      line-height: 1.6;
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        font-size: 14px;
      }
    }
  }

  section.bnr {
    margin-bottom: 3em;
    margin-top: 2em;
    @include media-breakpoint-up(md) {
      margin-top: 4em;
      margin-bottom: 4em;
    }

    .col-md-6 {
      margin-bottom: 1em;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    img {
      width: 100%;
      @include transition05;
    }

    a {
      &:hover {
        img {
          opacity: .6;
        }
      }
    }
  }

  .ggmap {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      padding-bottom: 400px;
    }
  }

  .ggmap iframe,
  .ggmap object,
  .ggmap embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  section.instagram-block,
  section.fb-block {
    .ttl-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .sns-btns {
      li {
        margin: 0;
      }

      img {
        width: 40px;
        @include media-breakpoint-up(md) {
          width: 50px;
        }
      }
    }
  }


  .fb-block {
    margin-bottom: 2em;
    width: 100%;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }


  .staff_s_blog-content {
    padding: 15px;
    background-color: #fff;
    @include media-breakpoint-up(md) {
      padding: 30px;
    }
  }

  .staff_s_blog-content-wrap {
    overflow: hidden;

    .item-more-wrap {
      margin-top: 30px;
      @include media-breakpoint-up(md) {
        float: right;
        width: 40%;
      }

      button {
        min-width: 47%;
        @include media-breakpoint-up(md) {
          width: 100%;
        }
      }
    }
  }

  .item-staff_s_blog {
    width: 100%;
    display: flex;
    flex-direction: row;


    .img-block {
      width: 100px;
      margin-right: .5em;

      a {
        text-align: center;
        position: relative;
      }

      img {
        width: auto;
        height: auto;
        object-fit: cover;
        position: relative;
        top: auto;
        left: auto;
        transform: none;
      }
    }

    .txt-block {
      padding-top: 0;
      flex: 1;
      border: none;

      .sub-ttl {
        display: none;
      }
    }


    &:nth-of-type(1) {
      margin-bottom: 45px;
      flex-direction: column;

      .img-block {
        width: auto;

        img {
          width: auto;
          height: auto;
        }
      }

      .txt-block {
        .sub-ttl {
          display: block;
          margin-top: .5em;
        }
      }
    }

    @include media-breakpoint-up(md) {
      float: right;
      width: 40%;
      height: 76px;
      .img-block {
      }
      .entry-title {
        font-size: 13px;
        margin-bottom: 0;
      }
      .entry-meta .posted-on {
        font-size: 10px;
      }
      &:nth-of-type(1) {
        margin-bottom: 0;
        float: left;
        width: 53%;
        display: block;
        height: auto;

        .img-block {
          display: block;
          width: 100%;
        }

        .txt-block {
          padding-bottom: 0;
          padding-left: 0;
          padding-right: 0;
        }

        .entry-title {
          font-size: 18px;
          margin-bottom: .5rem;
        }

        .entry-meta .posted-on {
          font-size: 13px;
        }

      }


    }

  }

  .mfp-inline-holder .mfp-content,
  .mfp-ajax-holder .mfp-content {
    @include media-breakpoint-up(md) {
      max-width: 960px;
    }
  }

  button.mfp-arrow, button.mfp-close {
    @extend %font-lato;
    top: -44px;
    color: #fff;
    font-size: 44px;
  }

  .bnr-gd {
    a {
      &:hover {
        opacity: .7;
      }
    }
  }

}


