$gd-color: #c33326;
body.page-template-good-days-tmpl-2022-08 {
  background-color: #6aefff;
}

body.page-template-good-days-tmpl-2022-09 {
  background-color: #f39800;
}

body.page-template-xmasgift-present-tmpl-2022-11 {
  background-color: #b0c559;
}

body.page-template-xmasgift-present-tmpl-2023-11-12 {
  background-color: #b0c559;
}

body.page-template-xmasgift-present-tmpl-2024-11-12 {
  background-color: #b0c559;
}

body.page-template-good-days-tmpl-2023-02 {
  background-color: #f8b2b2;
}

body.page-template-good-days-tmpl-2023-03 {
  background-color: #a3ddf2;
}

body.page-template-good-days-tmpl-2023-03-2 {
  background-color: #ffc8d4;
}

body.page-template-good-days-tmpl-2023-04 {
  background-color: #d20e31;
}

body.page-template-good-days-tmpl-2023-05-06 {
  background-color: #217edb;
}

body.page-template-good-days-tmpl-2023-07 {
  background-color: #ffde00;
}

body.page-template-good-days-tmpl-2023-07-08 {
  background-color: #6aefff;
}

body.page-template-good-days-tmpl-2023-09 {
  background-color: #c98d98;
}

body.page-template-good-days-tmpl-2023-03-2 #main.good-days .gd-read h3 {
  color: #3bb9b4;
}


.attention-box {
  .attention-1 {
    font-weight: bold;
    color: #fff;
    h3 {
      font-size: 14px;
    }
    p {
      font-size: 13px;
    }
  }
  .attention-3 {
    p {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

#main.good-days {
  .gd-main-image {
    h2 {
      margin-bottom: 0;
    }
  }
  .gd-read {
    margin: 0 0 1.5em 0;
    padding: 1.5em 0 .5em;
    text-align: center;
    background-color: #fff;

    h3 {
      font-weight: bold;
      color: $gd-color;
      font-size: 15px;
      @include media-breakpoint-up(md) {
        font-size: 20px;
      }
    }
  }

  .gd-page-nav {
    color: #f00;
    padding: 10px;
    @include media-breakpoint-up(md) {
      padding: 30px;
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    li {
      min-width: 49%;
    }
    a {
      color: #fff;
      display: block;
      font-size: 2.4vw !important;
      margin: 0.3em;
      @include media-breakpoint-up(md) {
        font-size: 16px !important;
      }
      &:hover {
        opacity: 0.7;
        color: #000 !important;
      }
    }
  }

  .btn-gourmet,
  .ttl-gourmet {
    background-color: #3bb9b4;
  }

  .btn-item,
  .ttl-item {
    background-color: #f8cc90;
  }

  .ttl-gourmet,
  .ttl-item {
    color: #fff;
    margin-top: 2em;
    margin-bottom: 1em;
    text-align: center;
    padding: 10px;
    font-family: "Lato", sans-serif;
    letter-spacing: .1em;
    font-size: 16px;
    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }

  .good-days-data-wrap {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &:after {
        content: "";
        display: block;
        width: 32.3%;
        height: 0;
      }
    }
  }


  .entry-item {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 32.3%;
    }

    a {
      text-decoration: none;

      &:hover {
        img {
          transform: scale(1.2) translate(-40%, -40%);
          transition: 1s all;
        }
      }
    }

    .img-block {
      height: calc(100vw - 30px);
      @include media-breakpoint-up(sm) {
        height: 510px;
      }
      @include media-breakpoint-up(md) {
        height: 223px;
      }
      @include media-breakpoint-up(lg) {
        height: 301px;
      }
      @include media-breakpoint-up(xl) {
        height: 407px;
      }
    }

    .entry-title {
      color: $gd-color;
      font-weight: bold;
      font-size: 15px;
    }

    .txt-block {
      padding: 15px;

      .sub-ttl {
        color: #000;
        font-weight: bold;
      }

      .price {
        font-weight: bold;
        margin-bottom: 0;
        font-size: 15px;
      }
    }
  }

}
